import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import Ripple from 'vue-ripple-directive';
Vue.config.productionTip = false;

Vue.directive('ripple', Ripple);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');