<template>
  <div class="login">
    <div class="image-container">
      <img src="@/assets/images/image-auth.png">
    </div>

    <div class="form-container">
      <div class="logo-container">
        <img src="@/assets/images/logo-red.png">
      </div>

        <slot name="content" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImageLayout'
  };
</script>

<style lang="scss" scoped>
  .login {
    grid-template-columns: 65% 35%;
    display: grid;
    height: 100vh;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background: radial-gradient(43.01% 51.63% at 51.61% 60.55%, #AA1428 0%, #6D0E1A 100%);

      img {
        width: 70rem;
      }
    }

    .form-container {
      background: #FFF;
      padding: 1.5rem;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      .logo-container {
        margin-bottom: 2rem;

        img {
          width: 245px;
          height: 60px;
        }
      }
    }
  }

  @media (max-width: 1417px) {
    .login {
      .image-container {
        img {
          width: 55rem;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .login {
        grid-template-columns: 55% 45%;
    }
  }

  @media (max-width: 768px) {
    .login {
      grid-template-columns: 1fr 100%;

      .form-container {
        border-top: 10px solid #AA1428;
      }
    }
  }
</style>