<template>
  <image-layout-component>
    <template v-slot:content>
      <div class="content">
        <div class="suggestions">
          <div v-if="username" class="card">
            <h3>
              Usuário:
            </h3>

            <card-component class="card-component" :text="username" :hasCopyButton="false" />
          </div>

          <div v-if="passwords" class="card">
            <h3>
              Senhas sugeridas:
            </h3>

            <div
              v-for="(item, index) in passwords"
              :key="index"
            >
              <card-component class="card-component" :text="item" :hasCopyButton="true" />
            </div>
          </div>
        </div>

        <button-component
          text="Acessar"
          class="button-component"
          styles="primary"
          size="large"
          :isDisabled="hasRequest"
          :hasLoader="hasRequest"
          @click.native="authenticate"
          v-ripple="'rgba(255, 255, 255, 0.35)'"
        />
      </div>
    </template>
  </image-layout-component>
</template>

<script>
  import ImageLayout from '@/components/Base/ImageLayout';
  import Card from '@/components/Card';
  import Button from '@/components/Button';
  import Keycloak from 'keycloak-js';

  export default {
    name: 'Login',

    data() {
      return {
        hasRequest: false,
        username: '',
        passwords: ''
      };
    },

    components: {
      'button-component': Button,
      'card-component': Card,
      'image-layout-component': ImageLayout
    },

    mounted() {
      this.username = this.$route.query.username;

      if (this.$route.query.passwords) {
        this.passwords = this.$route.query.passwords.split('@');
      }
    },

    methods: {
      async authenticate() {
        this.hasRequest = true;

        let keycloak = Keycloak({
          url: process.env.VUE_APP_KEYCLOAK_URL,
          realm: process.env.VUE_APP_KEYCLOAK_REALM,
          clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
        });

        console.log('Authenticating...');

        await keycloak.init({ onLoad: 'login-required', redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECT_URI })
          .then((auth) => {
            if(!auth)  {
              console.log('Not authenticated');
            } else {
              console.log('Authenticated');
            }

            console.log('vue-token', keycloak.token);
            console.log('vue-refresh-token', keycloak.refreshToken);
          }).catch(() => {
            console.log('Authenticated failed');
          });

        this.hasRequest = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .content {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .suggestions {
      width: 100%;
      margin-bottom: 0.5rem;

      h3 {
        text-align: center;
        margin-bottom: 1rem;
      }

      .card {
        margin-bottom: 2rem;
        color: #333;
      }
    }

    .message-container {
      margin-bottom: 1rem;
    }
  }
</style>