<template>
	<div>
		<div
			class="content"
		>
			<p ref="linkValue">
				{{text}}
			</p>

      <div v-if="hasCopyButton" class="icon-container">
        <i v-if="isCopied" class="icon-check-circle" />
        <i v-else class="icon-copy" @click="copy" />
      </div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'Card',

    data() {
      return {
        isCopied: false,
      };
    },

    props: {
      text: {
        type: String,
        default: 'text',
			},

			hasCopyButton: {
				type: Boolean,
				default: true
			}
    },

    methods: {
      copy() {
        this.isCopied = true;

        setTimeout(() => {
          this.isCopied = false;
        }, 1000);

        var value = this.$refs.linkValue.innerText;
        var currentElement = document.createElement('textarea');

        currentElement.value = value;
        currentElement.setAttribute('readonly', '');
        currentElement.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(currentElement);
        currentElement.select();
        document.execCommand('copy');
        document.body.removeChild(currentElement);
      }
    }
  };
</script>

<style lang="scss" scoped>
	.content {
		display: flex;
		align-items: center;
		padding: 1rem;
		border-radius: 0.5rem;
		background: #F9F9F9;
		color: #333;
		margin-bottom: 1rem;

		p {
			font-size: 1rem;
		}

    .icon-container {
      margin-left: auto;

      i {
        color: #8E8E8E;
        cursor: pointer;

        &:hover {
          color: #656262;
        }

        &.icon-check-circle {
          color: #08b978;
          font-size: 1.2rem;
          cursor: not-allowed;
        }
      }
    }
	}
</style>