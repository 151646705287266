import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Success from '@/views/Success.vue';
// import NotFound from '@/views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'login',
    component: Login,
    meta: {
      title: 'Entrar',
    }
  },

  {
    path: '/success',
    name: 'success',
    component: Success,
    meta: {
      title: 'Autenticado',
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Instituto Mix';
  }

  next();
});

export default router;