<template>
  <image-layout-component>
    <template v-slot:content>
      <div class="content">
        <i class="icon-check-circle" />

        <div class="message">
          <p>
            Cadastramento feito com sucesso!
          </p>

          <p>
            Você pode fechar essa página e voltar para o sistema.
          </p>
        </div>

      </div>
    </template>
  </image-layout-component>
</template>
x
<script>
  import ImageLayout from '@/components/Base/ImageLayout';

  export default {
    name: 'Login',

    components: {
      'image-layout-component': ImageLayout
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    i {
      margin-top: 1rem;
      font-size: 3rem;
      color: #08b978;
    }

    .message {
      margin-top: 1rem;

      p {
        text-align: center;
        margin-top: 0.2rem;
      }
    }
  }
</style>